:root {
    --SIBLING_MARGIN: 1.2rem;
    --CHILDREN_MARGIN_BOTTOM: 1.2rem;
}

.rich_text > *:not(:last-child) {
    margin-bottom: var(--SIBLING_MARGIN);
    
}
.rich_text > * *:not(:last-child) {
    margin-bottom: var(--CHILDREN_MARGIN_BOTTOM);
}

.rich_text {
   font-family: var(--PLEX_SANS);
      font-weight: var(--REGULAR);
      line-height: var(--L16);
      font-size: var(--F14);
      margin-bottom: var(--SIBLING_MARGIN);
      margin-top: var(--SIBLING_MARGIN);
}

.rich_text * {
      font-family: var(--PLEX_SANS);
      font-weight: var(--REGULAR);
      line-height: var(--L16);
      font-size: var(--F14);
      margin-bottom: var(--SIBLING_MARGIN);
      margin-top: var(--SIBLING_MARGIN);
}

 @media screen and (min-width: 768px) {
        .rich_text * {
            font-size: var(--F16);
        }
        .rich_text  {
            font-size: var(--F16);
        }
              

 }

 .rich_text strong {
    font-weight: var(--MEDIUM);
 }

 .rich_text ul {
    list-style-type: disc;
    margin-left: 18px;
 }

 .rich_text ol {
    list-style-type:decimal;
    margin-left: 18px;
 }

 .rich_text a {
   @apply break-all;
   color: #0060B6;
   text-decoration: underline;
 }

 .img {
    @apply h-fit max-h-[80rem]
 }

 .img_container {
    @apply flex flex-col justify-center items-center
 }

 .img_alt_text {
    @apply font-plex-sans leading-[2.4rem] font-[400] md:text-[1.6rem] text-[1.4rem] text-gslearnlightmodeGrey1 mx-auto
 }
